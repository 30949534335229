@import './global_variables';
@import './global_mixins';

@font-face {
  font-family: $font--plain-latin;
  src: local('Porsche Next Auto TT'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-Regular.ttf');
}

@font-face {
  font-family: $font--plain-je;
  src: local('PorscheNextAutoTT-Regular-J'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-Regular-Je.ttf');
}

@font-face {
  font-family: $font--plain-k;
  src: local('PorscheNextAutoTT-Regular-K'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-Regular-K.ttf');
}

@font-face {
  font-family: $font--plain-hk;
  src: local('PorscheNextAutoTT-Regular-SC-HK'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-Regular-SC-HK.ttf');
}

@font-face {
  font-family: $font--plain-tc;
  src: local('PorscheNextAutoTT-Regular-TC'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-Regular-TC.ttf');
}

@font-face {
  font-family: $font--semibold-latin;
  src: local('Porsche Next Auto TT SmBold'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-SmBold.ttf');
}

@font-face {
  font-family: $font--semibold-je;
  src: local('PorscheNextAutoTT-SmBold-J'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-SmBold-Je.ttf');
}

@font-face {
  font-family: $font--semibold-k;
  src: local('PorscheNextAutoTT-SmBold-K'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-SmBold-K.ttf');
}

@font-face {
  font-family: $font--semibold-hk;
  src: local('PorscheNextAutoTT-SmBold-SC-HK'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-SmBold-SC-HK.ttf');
}

@font-face {
  font-family: $font--semibold-tc;
  src: local('PorscheNextAutoTT-SmBold-TC'), url('../../pagCenter/assets/fonts/PorscheNextAutoTT-SmBold-TC.ttf');
}

@font-face {
  font-family: $font--plain-numeric;
  src: local('Porsche Next Auto Num TT'), url('../../pagCenter/assets/fonts/PorscheNextAutoNumTT-Regular.ttf');
}

@font-face {
  font-family: $font--semibold-numeric;
  src: local('Porsche Next Auto Num TT SemiBold'), url('../../pagCenter/assets/fonts/PorscheNextAutoNumTT-SemiBold.ttf');
}

@font-face {
  font-family: $font--monda;
  src: local('Monda'), url('../../pagCenter/assets/fonts/Monda-Regular.ttf');
}

@font-face {
  font-family: $font--plain-exp;
  src: local('Porsche Next Auto Expanded TT'), url('../../pagCenter/assets/fonts/PorscheNextAutoExpTT-Regular.ttf');
}

@font-face {
  font-family: $font--bold-exp;
  src: local('Porsche Next Auto Expanded TT Bold'), url('../../pagCenter/assets/fonts/PorscheNextAutoExpTT-Bold.ttf');
}

* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  user-select: none;
}

.content {
  position: relative;
  max-height: $display-height;
}

.animated-bg {
  &--growing-circle {
    @include pressed-growing-circle;
  }

  &--pressed {
    background-color: var(--btn-s-1-color);
    background-image: var(--btn-s-1-image);
    border: var(--btn-s-1-border);
    border-radius: var(--btn-s-1-radius);
    box-shadow: var(--btn-s-1-shadow);
  }
}

.state-pressed .animated-bg--growing-circle,
label:active .animated-bg--growing-circle,
label:active .pressed-state-animated-container-wrapper > .animated-bg--growing-circle {
  // sass-lint:disable-line no-combinators
  @include animation(pressed-growing-circle $time__animation-duration--slow $animation-timing-function--bezier-easeout 1 both);
}

.state-pressed .animated-bg--pressed,
label:active > .animated-bg--pressed,
label:active .pressed-state-animated-container-wrapper .animated-bg--pressed {
  // sass-lint:disable-line no-combinators
  @include animation(pressed-background $time__animation-duration--slow $animation-timing-function--bezier-easeout 1 both);
}
