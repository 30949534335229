@import "../../../../components/pagGlobalstyles/sass/global_variables";
@import "../../../../components/pagGlobalstyles/sass/global_animations";

$border-width: (3 / 4) * $grid__unit;

$list-item__background-color--even: $color__primary--first;
$list-item__background-color--odd: $color__white--10;

$scrollposition-indicator__width: $grid__unit - $grid__unit / $grid__unit; // 3px
$scrollposition-indicator__margin--left-right: $spacer__m; // 16px

$dynamic-item-container__start-color: $color__primary--first;
$dynamic-item-container__end-color: #121212;
$dynamic-item-container__border--phone-list: 2px solid $color__white--15;
$dynamic-item-container__width--phone-list: $grid__column-width * 2 + $spacer__m; // 518px
$dynamic-item-container__background-image--phone-list: radial-gradient(
  ellipse 300% 100% at 50% 0%,
  $color__white--15 0%,
  $color__white--10 100%
);
$dynamic-item-container__border-radius: $grid__unit - 1;

$shadow-color--1: rgba(0, 0, 0, 1);
$shadow-color--2: rgba(0, 0, 0, 0.2);

$dynamic-item-container-shadow__top-colors: linear-gradient(
  to bottom,
  $shadow-color--1 0%,
  $shadow-color--2 60%,
  transparent 100%
);
$dynamic-item-container-shadow__width: calc(100% + 4px);
$dynamic-item-container-shadow__top: 0;
$dynamic-item-container-shadow__right: $grid__unit / -2; // -2px
$dynamic-item-container-shadow__left: $grid__unit / -2; // -2px
$dynamic-item-container-shadow__height: 2 * $spacer__l;
$dynamic-item-container-shadow__opacity: 0.72;

$list-container__margin--right: 4.25 * $grid__unit; // 17px

$blockitem__darkwhite: #d3d3d3;
$blockitem__gray: #333;
$blockitem__black: #000;

$invisible-overlay-to-stop-click-through__background-color: rgba(0, 0, 0, 0.5);

.list {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  #{--boxed}: true;
  // we now don't have backgrounds anymore thus we can bounce our list over the SubTabBar
  // if we don't make the list hide its overflow
  overflow: hidden;

  // we only want to show our various custom scrollbars, never the browser one
  ::-webkit-scrollbar {
    // sass-lint:disable-line no-vendor-prefixes
    display: none;
  }

  &.list--tile,
  &.list--no-text-fade {
    .list__container {
      -webkit-mask-image: none; // sass-lint:disable-line no-vendor-prefixes
    }
  }

  &.header-outside-list {
    height: calc(100% - #{$grid__row-height});
  }

  // The overflow: hidden we set in :host breaks slideout styles
  // thus we have a @HostBinding and unset the overflow as long as a slideout is open.
  // And because of style encapsulation we need to do it with a pseudo-selector
  &.list-host--overflow-unset {
    overflow: unset;
  }
}

.list__list-header {
  height: $grid__row-height;
  width: 100%;
}

.list__everything-but-static-header-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
  overflow: hidden;

  &--with-quickfilter {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }
}

.list--scroll-snap-proximity {
  scroll-snap-type: y proximity;
}

.list--scroll-snap-mandatory {
  scroll-snap-type: y mandatory;
}

.list__container {
  &,
  & * {
    touch-action: pan-y !important;
  }
  @include scrollbar-styles($color__scrollbar--default, $color__scrollbar--thumb, $border-width, $grid__unit);
  @include text-fading(top);
  flex-grow: 1;
  overflow-y: auto;
  overflow-anchor: none; // experimental Chrome-Property to disable scroll anchoring in Chrome, because that would conflict with out own scroll stabilization

  // 'top' is not really changed - but it creates a new compositing layer WITHOUT creating a new stacking context!
  will-change: scroll-position, top;

  // to avoid being able to scroll deletable-list-item-styling-elements into view.
  // can probably be removed when the deletable-list-item does not rely on a wider element moved into view.
  // after removing this we might have to re-add 'touch-action: pan-y;'
  overflow-x: hidden;
  //firefox
  scrollbar-width: none;

  &--no-scrolling {
    overflow: hidden;
  }

  &--with-quickfilter {
    position: relative;
    top: $grid__row-height;
    height: calc(100% - 3.5 * #{$grid__unit});
    overflow: hidden;

    .list__invisible-overlay-to-stop-click-through {
      bottom: -$grid__row-height;
    }
  }

  &--no-scroll-snap {
    scroll-snap-type: none;
  }
}

.list__loading-indicator-container {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.list__content-container {
  height: 100%;
  will-change: transform, opacity;
}

.list-item__container {
  &.mib3-dragged {
    @include pressed-background;
    opacity: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: $border__width--default;
      width: 100%;
      background-color: $border__color--primary;
    }
  }

  &.state-focused {
    @include focus-state("list-item");
    z-index: 1;
  }
}

.list__invisible-overlay-to-stop-click-through {
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $invisible-overlay-to-stop-click-through__background-color;
}

.list__spaceholder-to-avoid-double-lines-with-last-item {
  height: ($display-height % $grid__row-height);
  width: 100%;
}

.list__element--visible-scrollbar {
  width: calc(100% - (#{$scrollposition-indicator__width} + #{$scrollposition-indicator__margin--left-right}));
}

.list__element--visible-fastscrollbar {
  width: calc(100% - #{$letter-slider__width});
}

.solid-lines {
  background-image: linear-gradient($color__white--50 1px, transparent 1px);
  background-size: 100% 118px;
}

.list__block-item {
  height: 118px;
  color: $blockitem__darkwhite;
  padding: 0.5em;
  font-size: 38px;
  scroll-snap-align: start;
  position: relative;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $blockitem__gray;
    box-shadow: 0 0 2px 0 $blockitem__black;
  }
}

:host-context(.alternative-routes-list),
:host-context(.tourplan-list),
:host-context(.multiple-routes__list),
:host-context(.phone-list),
:host-context(.location-selected-expanded) {
  .list__container {
    -webkit-mask-image: none; // sass-lint:disable-line no-vendor-prefixes
  }
}

:host-context(.map-view-options),
:host-context(.overview-list) {
  .list__container {
    -webkit-mask-image: none; // sass-lint:disable-line no-vendor-prefixes
  }

  .list__spaceholder-to-avoid-double-lines-with-last-item {
    display: none;
  }
}

:host-context(.source-browser-full-screen-right-steering-wheel) {
  // sass-lint:disable-line no-combinators
  .list__list-header {
    margin: 0 $spacer__xs 0 $screen__width--detail-screen + 3 * $spacer__xs;
  }
}

:host-context(.source-browser-full-screen-left-steering-wheel) {
  // sass-lint:disable-line no-combinators
  .list__list-header {
    margin: 0 $spacer__xs;
  }
}

:host-context(.ww__list) {
  .list__container {
    -webkit-mask-image: none; // sass-lint:disable-line no-vendor-prefixes
  }
}

.list.device-list {
  .list-item__container {
    &:nth-child(even) {
      background-color: $list-item__background-color--even;
    }

    &:nth-child(even).device-list__connection-types {
      background-color: $list-item__background-color--even;

      .device-list__connection-types {
        background-color: $list-item__background-color--even;
      }
    }

    &:nth-child(odd) {
      background-color: $list-item__background-color--odd;
    }

    &:nth-child(odd).device-list__connection-types {
      background-color: $list-item__background-color--odd;

      .device-list__connection-types {
        background-color: $list-item__background-color--odd;
      }
    }
  }
}

:host-context(.partial-popup__content--list) {
  height: auto;

  .list__container {
    @include list-fading(572px);
  }
}

:host-context(.sound-screen__switch) {
  overflow: unset;
}

.list-item__dynamic-item-container {
  width: 100%;
}

:host-container(.bluetooth-device-list__results) {
  .list__container {
    -webkit-mask-image: none; // sass-lint:disable-line no-vendor-prefixes
  }
}

#{$selector-latin-layout} {
  .list__container--with-scroll-bar {
    clip-path: polygon(
      0 0,
      calc(100% - #{$list-container__margin--right}) 0%,
      calc(100% - #{$list-container__margin--right}) 100%,
      0% 100%
    );
  }
}

#{$selector-arabic-layout} {
  direction: rtl;

  .list__list-header {
    margin-right: 0;
  }

  .list__container--with-scroll-bar {
    clip-path: polygon(#{$list-container__margin--right} 0%, 100% 0, 100% 100%, #{$list-container__margin--right} 100%);
  }
}
