@import "../../../pagGlobalstyles/sass/fonts";
@import "../../../pagGlobalstyles/sass/global_variables";
@import "../../../pagGlobalstyles/sass/global_animations";

$button__width: $size__xl;
$button__background--disabled: $color__primary--first;
$button-icon__width: $icon-size__m;
$button-icon__margin: $spacer__m;
$button__text-color: $color__normal;
$button__text-color--pressed: $color__primary--second;
$button__text-color--active: $color__active;
$button__text-color--disabled: $color__disabled;
$button__text-height: $font__line-height--m;
$button__text-max-width: 50 * $grid__unit;
$button__max-width--bluetooth-search: $screen__width--detail-screen - $spacer__m * 2;
$button__margin-top--bluetooth-search: -4.5 * $grid__unit; // -18px
$button__max-height--bluetooth-search: 16 * $grid__unit; // 64px

$button__width--speec-available-notification: 121.5 * $grid__unit; // 486px

$button-text__width--max: 92.5 * $grid__unit; // 370px
$button-text__line-height: $font__line-height--m;
$button-text__height--max: $button-text__line-height;

$button__overlay-container--max-width: 124.5 * $grid__unit; // 498px
$button__overlay-container--max-height: 13.5 * $grid__unit; // 54px

$partial-popup--width: 267 * $grid__unit;
$partial-popup__button--width: ($partial-popup--width - (2 * $spacer__l) - (4 * $spacer__m)) / 3; // 313.33px
$partial-popup__button--max-width: ($screen__width--interaction-screen - (3 * $spacer__l)) / 2; // 478px

$notification__button-small--max-width: ($screen__width--interaction-screen - (4 * $spacer__l)) / 3; // 308px
$notification__button-medium--width: 78.25 * $grid__unit; // 313px
$notification__button-large--max-width: ($screen__width--interaction-screen - (3 * $spacer__l)) / 2; // 478px

$button__max-width-notification--small: $notification__button-small--max-width - $spacer__l - $icon-size__m - $spacer__m; // 200px
$button__max-width-notification--large: $notification__button-large--max-width - $spacer__l - $icon-size__m - $spacer__m; // 370px

$button__text-max-width-notification--large: 92 * $grid__unit;

$color__icon--phone-receiver: $color__positive-green;
$color__icon--hang-up: $color__negative-red;
$notification-action__margin--top: 0.5 * $grid__unit; // 2px

$notification__margin: $notification-action__margin--top $spacer__m 0;
$notification__padding: 0 $spacer__m;

$popup-text__line-height: $font__line-height--s;

$button-without-icon__max-width--small: $notification__button-small--max-width - 2 * $spacer__m; // 276px
$button-without-icon__max-width--large: $notification__button-large--max-width - 2 * $spacer__m; // 446px

$button-container__max-width--popup-interaction-area: 61.75 * $grid__unit; // 247px

$button-text__max-width--notification-popup: 103 * $grid__unit; // 412px
$button-text__margin-top--notification-popup: 0.5 * $grid__unit; // 2px

$button__border-radius--notification-popup: 2.5 * $grid__unit; // 10px

$call-to-action__color-from: #340805;
$call-to-action__color-to: #66100a;
$call-to-action__background-color: linear-gradient(1deg, $call-to-action__color-from 0%, $call-to-action__color-to 98%);

$map-tooltip__box-border--call-to-action: inset 0 0 0 2px rgba($color__negative-red, 0.5);
$map-tooltip__box-shadow: 0 10px 15px 0 rgba($color__primary--first, 0.3);

.button__host {
  @include background("buttons-tiles", "normal");
  display: flex;
  justify-content: center;
  position: relative;
  min-width: $button__width;
  min-height: $button__width;
  padding: $spacer__m;
  overflow: hidden;
  font-size: $font__size--normal;
  transition: background-image $time__animation-duration--medium ease-out;
  white-space: nowrap;
  border-width: 0;
  #{--boxed}: true;

  .animated-bg--pressed {
    position: absolute;
    top: 0; right: 0; bottom: 0; left: 0;
  }

  &.partial-popup__button {
    min-width: $partial-popup__button--width;
    max-width: $partial-popup__button--max-width;
    margin-inline-start: $spacer__m;

    &--big {
      width: $partial-popup__button--max-width;
    }

    .button__container {
      justify-content: center;
    }

    .button__fading {
      width: 100%;

      // #{$selector-latin-layout} & {
      @include text-fading(left);
      // }

      #{$selector-arabic-layout} & {
        @include text-fading(right);
      }
    }
  }

  &.state-active {
    @include background("buttons-tiles", "active");

    .button__icon {
      @include icon-style("active", "solid");
    }

    .button__text {
      color: $button__text-color--active;
    }
  }

  &.state-pressed {
    .button__icon {
      @include icon-style("pressed", "solid");
    }

    .button__text {
      color: $button__text-color--pressed;
    }
  }

  &.state-disabled {
    @include background("buttons-tiles", "disabled");
    cursor: not-allowed;

    .button__icon {
      @include icon-style("disabled", "solid", 0);
    }

    .button__text {
      color: $button__text-color--disabled;
    }
  }

  &.state-focused {
    @include focus-state;
  }

  &.button--icon-only {
    .button__container {
      max-width: $button-icon__width;
      max-height: $button-icon__width;
    }
  }
}

.call-button {
  .button__text {
    max-width: $button-text__width--max;
  }
}

.confirm-button {
  .button__fading {
    width: 100%;

    .#{$class-name-arabic-layout} & {
      @include text-fading(right);
    }

    .#{$class-name-latin-layout} & {
      @include text-fading(left);
    }
  }

  .button__container {
    justify-content: center;
  }
}

.icon-phone_hang_up {
  // sass-lint:disable-line class-name-format
  &::before {
    color: $color__negative-red;
    background-image: none;
  }
}

.button {
  &__container {
    display: flex;
    align-items: center;
    align-self: center;
    position: relative;
    height: 100%;
  }

  &__icon {
    @include icon-style("enabled", "solid");
    display: flex;
    flex-shrink: 0;
    width: $button-icon__width !important;
    height: $button-icon__width;
    font-size: $button-icon__width;
  }

  &__text {
    max-height: $button-text__height--max;
    line-height: $button-text__line-height;
    color: $button__text-color;
  }

  &__fading {
    align-self: center;
    height: 100%;
    max-width: 100%;
  }
}

// #{$selector-latin-layout} {
.button__container {
  flex-direction: row;
  direction: ltr;
}

.button__text.overflow-x {
  @include text-fading(left);
}

.button__icon {
  margin-right: $button-icon__margin;

  &.icon-phone_hang_up {
    // sass-lint:disable-line class-name-format
    &::before {
      color: $color__negative-red;
      background-image: none;
    }
  }
}

.button--icon-only {
  margin-right: 0;

  .button__icon {
    margin-right: 0;
  }
}
// }

#{$selector-arabic-layout} {
  .button__container {
    direction: rtl;
  }

  .button__text.overflow-x {
    @include text-fading(right);
  }

  .button__icon {
    margin-left: $button-icon__margin;
  }

  &.button--icon-only {
    margin-left: 0;

    .button__icon {
      margin-left: 0;
    }
  }
}

.button--call-to-action {
  background-image: $call-to-action__background-color;
  box-shadow: $map-tooltip__box-border--call-to-action, $map-tooltip__box-shadow;
}

.popup--incoming-call {
  .button__icon {
    margin-right: $button-icon__margin;
    -webkit-text-stroke-width: 0; // sass-lint:disable-line no-vendor-prefixes
    background-image: none;

    &::before {
      // sass-lint:disable-line nesting-depth
      text-shadow: none;
    }
  }

  .icon-phone_receiver_centered {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__icon--phone-receiver; // sass-lint:disable-line no-vendor-prefixes
    background-color: unset;
  }

  .icon-phone_hang_up {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__icon--hang-up; // sass-lint:disable-line no-vendor-prefixes
    background-color: unset;
  }

  .icon-phone_receiver {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__positive-green; // sass-lint:disable-line no-vendor-prefixes
    background-color: unset;
  }
}

.popup--outgoing-call {
  .button__icon {
    margin-right: $button-icon__margin;
    -webkit-text-stroke-width: 0; // sass-lint:disable-line no-vendor-prefixes
    background-image: none;
  }

  .icon-phone_hang_up {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__icon--hang-up; // sass-lint:disable-line no-vendor-prefixes
    background-color: unset;
  }
}

.notification__actions {
  width: $notification__button-medium--width;
  margin: $notification__margin;
  padding: $notification__padding;

  .button__text {
    height: $button__text-height;
    max-width: $button__text-max-width-notification--large;
  }
}

.notification__actions--one-line {
  .button__text {
    height: $button__text-height;
    max-width: $button__text-max-width-notification--large;
    white-space: nowrap;
  }
}
.speech-call-button--green {
  .icon-phone_receiver_green {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__icon--phone-receiver; // sass-lint:disable-line no-vendor-prefixes
  }
}

.notification__actions-button {
  .icon-phone_receiver_green {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__icon--phone-receiver; // sass-lint:disable-line no-vendor-prefixes
  }

  .icon-phone_hang_up {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__icon--hang-up; // sass-lint:disable-line no-vendor-prefixes
  }

  .button__fading {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .button__text {
    max-width: $button__max-width-notification--large;
  }

  .notification__actions--one-line & {
    .button__text {
      max-width: $button__max-width-notification--small;
    }
  }
}

.popup__interaction-area {
  .button__text {
    margin-top: $grid__unit;
  }

  .button__container {
    max-width: $button-container__max-width--popup-interaction-area;
    margin-left: $spacer__m;
    margin-right: $spacer__m;
  }
}

.service-call-button {
  .button__text {
    margin-top: $size__s;
  }
}

.external-notification,
.missed-call,
.pnav-notification,
.update-notification,
.speech-expert-mode-notification,
.speech-online-available-notification,
.speech-available-notification,
.update-successful {
  .button__text {
    max-width: $button-text__max-width--notification-popup;
  }
}

.speech-available-notification {
  width: $button__width--speec-available-notification;
}

.emergency-exit-button {
  .button__text {
    max-width: $button__width--speec-available-notification;
    white-space: nowrap;
  }
}

.list-headline__start-content {
  .button__fading {
    width: 100%;
    overflow: hidden;
  }

  .button__container {
    justify-content: center;
  }
}

.list-headline__start-content {
  // .#{$class-name-latin-layout} &) {
  .button__fading {
    @include text-fading(left);
  }
}

// .#{$class-name-arabic-layout} &) {
//   .button__fading {
//     @include text-fading(right);
//   }
//   }
// }

.button__text-fading {
  // .#{$class-name-latin-layout} &) {
  .button__text {
    @include text-fading(left);
  }
}

//   .#{$class-name-arabic-layout} &) {
//     .button__text {
//       @include text-fading(right);
//     }
//   }
// }

.guidance-active {
  .button__fading {
    width: 100%;
  }

  .button__text {
    width: 100%;
    text-align: center;
  }
}

.bluetooth-device-list__search-button {
  .button__fading {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
  }

  .button__text {
    max-height: $button__max-height--bluetooth-search;
    max-width: $button__max-width--bluetooth-search;
    margin-top: $button__margin-top--bluetooth-search;
    padding: $spacer__m;
  }
}

.device-list__search-button {
  .button__fading {
    // .#{$class-name-latin-layout} & {
    -webkit-mask-image: none; // sass-lint:disable-line no-vendor-prefixes
    // }

    // .#{$class-name-arabic-layout} & {
    -webkit-mask-image: none; // sass-lint:disable-line no-vendor-prefixes
    // }
  }
}

.my-screen__confirm-button {
  .button__text {
    @include text-tool-label(
      $button__overlay-container--max-height,
      $button__overlay-container--max-width,
      overflow,
      pre-wrap
    );
  }
}

.overlay__button {
  .button__text {
    @include text-tool-label(
      $button__overlay-container--max-height,
      $button__overlay-container--max-width,
      overflow,
      pre-wrap
    );
  }
}

.ww__button {
  .button__text {
    max-width: 100%;
  }
}

.popup--roa-ld-connected {
  .icon-phone_hang_up {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__icon--hang-up; // sass-lint:disable-line no-vendor-prefixes
  }
}

.homelink__content,
.accesspoint-list,
.sds-disabler-popup__content,
.sds-settings-popup__interaction,
.connect-phone__interaction,
.privacy-popup__interaction,
.welcome-popup__interaction {
  .button__text {
    width: 100%;
    max-width: $notification__button-large--max-width;
    max-height: $popup-text__line-height;
    line-height: $popup-text__line-height;
    overflow: hidden;
    text-align: center;
    white-space: nowrap;
  }
}

.epb-popup,
.popup-16-pop-confirm-mode-change,
.popup-16-pop-account-delete,
.popup-03-smartphone-integration-legal-disclaimer,
.popup-16-pop-account-loading,
.popup-16-pop-account-logout,
.popup-03-opt-pop-resetonlinedatacounter,
.popup-03-pop-smartphone-connection-mode,
.popup-31-pop-speechonlinedisclaimer,
.popup-07-pop-spoiler-retract-in-standstill,
.popup-02-txt-addsdarsnotices,
.interaction-screen__buttons,
.porsche-pcm-button,
.carplay-button,
.search-in-area-button,
.search-in-area-continue-button,
.info-message-button,
.informations-button,
.source-error-message-button {
  .button__container {
    max-width: $notification__button-large--max-width;
  }

  .button__text {
    max-width: $button-without-icon__max-width--large;
    white-space: nowrap;
  }
}

.popup-16-pop-account-switch,
.keyboard__locked-overlay {
  .button__container {
    max-width: $notification__button-large--max-width;
  }

  .button__text {
    max-width: $button__max-width-notification--large;
    white-space: nowrap;
  }
}

.info-message__button {
  .button__text {
    text-align: center;
  }
}

.readout-active {
  .button__icon {
    @include icon-style("active", "solid");
  }
}

.tile-footer__button--bluetooth {
  .button__icon {
    font-size: $icon-size__s;
  }
}

.position-icon--right {
  .button__container {
    flex-direction: row-reverse;
  }

  .button__icon {
    .#{$class-name-arabic-layout} & {
      margin-right: $button-icon__margin;
    }

    // .#{$class-name-latin-layout} & {
    margin-left: $button-icon__margin;
    // }
  }
}

.concierge-connected-popup {
  .icon-phone_hang_up {
    // sass-lint:disable-line class-name-format
    -webkit-text-fill-color: $color__icon--hang-up; // sass-lint:disable-line no-vendor-prefixes
  }
}
