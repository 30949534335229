@import './global_variables';

// sass-lint:disable no-vendor-prefixes
// Porsche Plain font
@mixin font-pag-plain {
  font-family: $font--plain, sans-serif;
  -webkit-font-smoothing: antialiased;
}

// Porsche Semibold font
@mixin font-pag-semibold {
  font-family: $font--semibold, sans-serif;
  -webkit-font-smoothing: antialiased;
}

// Porsche Plain Numeric font
@mixin font-pag-numeric-plain {
  font-family: $font--plain-numeric, sans-serif;
  -webkit-font-smoothing: antialiased;
}

// Porsche Semibold Numeric font
@mixin font-pag-numeric-semibold {
  font-family: $font--semibold-numeric, sans-serif;
  -webkit-font-smoothing: antialiased;
}

// Porsche Plain Expanded font
@mixin font-pag-plain-exp {
  font-family: $font--plain-exp, sans-serif;
  -webkit-font-smoothing: antialiased;
}

// Porsche Bold Expanded font
@mixin font-pag-bold-exp {
  font-family: $font--bold-exp, sans-serif;
  -webkit-font-smoothing: antialiased;
}

// Monda Regular Password font
@mixin font-monda-password-regular {
  font-family: $font--monda, sans-serif;
  -webkit-font-smoothing: antialiased;
}

// Porsche Icon font
@mixin font-pag-icon {
  font-family: $font--icons, sans-serif;
  -webkit-font-smoothing: antialiased;
}

.font-pag-plain {
  @include font-pag-plain;
}

.font-pag-semibold {
  @include font-pag-semibold;
}

.font-pag-numeric-plain {
  @include font-pag-numeric-plain;
}

.font-pag-numeric-semibold {
  @include font-pag-numeric-semibold;
}

.font-monda-password-regular {
  @include font-monda-password-regular;
}

.font-pag-icon {
  @include font-pag-icon;
}
