@import "../../../../../../../pagGlobalstyles/sass/global_variables";
@import "../../../../../../../pagGlobalstyles/sass/fonts";

$route-info-elem__margin: 3.75 * $grid__unit;
$route-info-eta-mode__margin: 3.5 * $grid__unit;
$route-info-elem__margin: 3.75 * $grid__unit;
$route-info-eta-mode__margin: 3.5 * $grid__unit;

$route-info-soc-icon__width--location-selected-expanded: 11 * $grid__unit; // 44px

$provider-icon__height: 25 * $grid__unit; // 100px
$provider-icon__width: 100 * $grid__unit; // 400px
$provider-icon__fontsize: 37.5 * $grid__unit; // 150px

$icon-start-navigation__margin-left--location-selected-expanded: 3.75 * $grid__unit; // 15px
$icon-start-navigation__width--details-buttons--location-selected-expanded: 184 * $grid__unit; // 736px
$icon-start-navigation__height--location-selected-expanded: 120 * $grid__unit; // 480px
$icon-start-navigation__margin-right--location-selected-expanded: 1.75 * $grid__unit; // 7px

$address-item-expanded__height: 53 * $grid__unit - $grid__unit / 4; // 211px
$outer-container-height: $display-height - 2 * $spacer__s; // 696px
$bottom-spacing-to-make-the-list-scrollbar-look-normal: $spacer__s; // 12px

.map-tooltip-detial-buttons-wrapper {
  display: block;
  #{--boxed}: false;
  height: $outer-container-height - $address-item-expanded__height -
    $bottom-spacing-to-make-the-list-scrollbar-look-normal;
}

.route-info {
  display: flex;
  align-items: center;
  line-height: $font__line-height--medium;

  &__soc {
    display: inline-flex;
    align-items: center;
    line-height: $font__line-height--medium;
  }
}

// design affairs should decide whether we need it
//.route-info__rrd,
//.route-info__eta-mode,
//.route-info__soc {
//  flex: 1;
//}

.icon--start-navigation {
  &::before {
    text-shadow: none;
  }
}

.map-tooltip-detial-buttons-wrapper.location-selected-expanded {
  .icon--start-navigation {
    &::before {
      margin-left: $icon-start-navigation__margin-left--location-selected-expanded;
    }
  }

  .detail-buttons {
    width: $icon-start-navigation__width--details-buttons--location-selected-expanded;
    height: $icon-start-navigation__height--location-selected-expanded;
  }
}

.map-tooltip-detial-buttons-wrapper.#{$class-name-arabic-layout}.location-selected-expanded {
  // sass-lint:disable-line no-combinators
  .route-info__soc-icon {
    width: $route-info-soc-icon__width--location-selected-expanded;
  }

  .route-info__soc {
    margin-right: unset;
  }

  .icon--start-navigation {
    &::before {
      margin-left: unset;
      margin-right: $icon-start-navigation__margin-right--location-selected-expanded;
    }
  }
}

.route-info__soc {
  margin-left: $route-info-elem__margin;
  margin-right: $route-info-elem__margin;
}

.route-info__soc-icon {
  display: inline-block;
  margin: 0 $spacer__xs 0 0;
  font-size: $font__size--normal;
  width: auto;

  &::before {
    position: relative;
  }

  &--charge-medium {
    &::before {
      color: $color__focus-orange;
    }
  }

  &--charge-low {
    &::before {
      color: $color__negative-red;
    }
  }
}

.powered-by-google-svg {
  display: flex;
  height: $provider-icon__height;
  width: $provider-icon__width;
  justify-content: center;
  align-items: center;
  align-self: center;
  overflow: visible;
}

.google-background {
  background: $color__white--20;
}

.powered-by-google {
  display: flex;
  height: $provider-icon__height;
  width: $provider-icon__width;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
  font-size: $provider-icon__fontsize;
}

// #{$selector-latin-layout} {
.route-info__eta-mode {
  margin-right: $spacer__m;
}

// .route-info__rrd {
//   margin-right: $route-info-elem__margin;
//   margin-left: $route-info-elem__margin;
// }
// }

#{$selector-arabic-layout} {
  .route-info__eta-mode {
    margin-left: $spacer__m;
  }

  .route-info__rrd {
    margin-left: $route-info-elem__margin;
    margin-right: $route-info-elem__margin;
  }
}
