@import "../../../../../../../pagGlobalstyles/sass/global_variables";

.map-tooltip-expanded-wrapper {
  height: auto;
  max-height: $display-height - 2 * $spacer__s;
  #{--boxed}: true;
}

.tooltip-expanded-content {
  width: 100%;
  height: $display-height - 2 * $spacer__s;
}
