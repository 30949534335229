.img-connector {
    .keypad__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        padding: 12px 12px 12px 0;
        --boxed: true;
    }

    .keypad__row {
        height: 108px;
        display: flex;
        margin-bottom: 0;
    }

    .keypad__row-1,
    .keypad__row-2,
    .keypad__row-3,
    .keypad__row-4 {
        margin-bottom: 10px;
    }



    .key-wrapper:first-child {
        margin-left: 0;
    }

    .key-wrapper {
        cursor: pointer;
    }

    .key-wrapper .key__container {
        font-family: Porsche Plain Latin, Porsche Plain JE, Porsche Plain K, Porsche Plain HK, Porsche Plain TC, sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        width: 100%;
        overflow: visible;
        font-size: 52px;
        line-height: 60px;
        border: 1px solid #1a1a1a;
        border-radius: 2px;
        box-shadow: inset 0 0 0 2px hsla(0, 0%, 80%, .1);
        color: #ccc;
        background-color: #1a1a1a;
        will-change: transform;
        --boxed: true;

    }


    .key-wrapper .key__animation-container {
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    @keyframes pressed-growing-circle {
        0% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(.1);
        }

        50% {
            opacity: .3;
        }

        100% {
            opacity: 0;
            transform: translate(-50%, -50%) scale(1);
        }
    }

    .animated-bg--growing-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 125%;
        padding-top: 125%;
        opacity: 0;
        border-radius: 50%;
        pointer-events: none;
        -webkit-transform: translate(-50%, -50%) scale(.1);
        transform: translate(-50%, -50%) scale(.1);
        background-color: #00b0f4;

    }

    .state-pressed .animated-bg--growing-circle {
        animation: pressed-growing-circle 0.25s ease-out 1 both;

    }

    .key-wrapper .animated-bg--pressed {
        box-shadow: inset 0 0 0 2px rgba(0, 176, 244, .2);
    }

    .animated-bg--pressed {
        border-radius: 2px;
    }

    .animated-bg--pressed {
        background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #001e38), to(#06131b));
        /*background-image: linear-gradient(0deg, #001e38 0, #06131b);*/
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        position: absolute;
        pointer-events: none;
    }

    .keypad__pad .key__content-container {
        height: 45px;
    }

    .key__content-container {
        display: block;
        position: relative;
    }

    .key__secondary-icon {
        align-items: center;
        height: 42px;
        font-size: 32px;
    }

    .key__secondary-icon,
    .key__secondary-label {
        position: relative;
        line-height: 42px;
        font-size: 32px;
        color: #999;
    }

    .icon {
        font-family: pag3-icon-font, sans-serif;
        -webkit-font-smoothing: antialiased;
        display: flex;
        height: 100%;
        width: 100%;
        background-size: contain;
        position: relative;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        align-items: center;
        justify-content: center;
        --boxed: true;
    }

    [class*=" icon-"],
    [class^=icon-] {
        font-family: Porsche-UI-Icons !important;
        speak: none;
        font-style: normal;
        font-weight: 400;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    .key-in-the-middle {
        padding-left: 3px;
        padding-right: 3px;
        margin-left: 5px;
        margin-right: 5px;
    }

    .key-wrapper:last-child,
    :host-context(.component-layout-arabic) :host:first-child {
        margin-right: 0;
    }

    .key__secondary-icon,
    .key__secondary-label {
        position: relative;
        line-height: 42px;
        height: 42px;
        font-size: 32px;
        color: #999;
    }

    .green .icon:after,
    .green .icon:before {
        color: #13d246 !important;
    }

    .red .icon:after,
    .red .icon:before {
        color: #950014 !important;
    }

}
