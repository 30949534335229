@import "../../../../pagGlobalstyles/sass/global_variables";

$update-description-headline__font-size: $font__size--m;
$update-description-headline__line-height: $font__line-height--m;
$update-description-headline__height--max: $update-description-headline__line-height;

$update-description-text__font-size: $font__size--normal;
$update-description-text__line-height: $font__line-height--s;
$update-description-text__max-height: 40 * $font__line-height--s;

$scroll-pabel__margin-top: 3 * $grid__unit; // 12px
$scroll-panel__height: $display-height - $grid__row-height - $scroll-pabel__margin-top;

.update-details-container {
  &__headline-text {
    display: flex;
    width: 100%;
    height: $grid__row-height;
    font-size: $update-description-headline__font-size;
    line-height: $update-description-headline__line-height;
    overflow: hidden;
    justify-content: center;
  }

  // overriding the scroll panel margin-top for the description-area since the available-details-screen has non scrollable headline on top
  &__description-area {
    width: 100%;
    height: $scroll-panel__height;
    padding: $spacer__m;
    margin-top: $scroll-pabel__margin-top;
  }

  &__description,
  &__label {
    color: white;
    display: flex;
    width: 100%;
    max-height: $update-description-text__max-height;
    line-height: $update-description-text__line-height;
    font-size: $update-description-text__font-size;
    overflow: hidden;
    text-align: left;
    white-space: pre-wrap !important; // sass-lint:disable-line no-important
  }
}
