@import "../../../pagGlobalstyles/sass/global_variables";
@import "../../../pagGlobalstyles/sass/global_mixins";

$scroll-panel__margin--top: $spacer__s; // 12px
$scroll-panel__padding: 0 $spacer__m; // 0 16px
$scroll-panel__height: $display-height - (38.5 * $grid__unit); // 566px

$scroll-panel-content__padding: $spacer__s;
$scroll-panel-content__height--max-description-area: 70 * $font__line-height--s;

$description-area__margin-top: $spacer__s;
$description-area__height: $display-height - $grid__row-height - (2 * $description-area__margin-top);

$partial-popup-scroll-panel__max-height: 102.5 * $grid__unit; // 410px

.scroll-panel-wrapper {
  // sass-lint:disable-block no-vendor-prefixes
  @include scrollbar-styles($color__scrollbar--default, $color__scrollbar--thumb, $size__s, $grid__unit + 1);
  display: block;
  overflow-y: auto;
  height: 100%;
  #{--boxed}: true;

  &.details-screen__text-container {
    margin-top: $description-area__margin-top;

    .content {
      display: flex;
      flex-direction: column;
    }
  }

  &.partial-popup__scroll-panel {
    max-height: $partial-popup-scroll-panel__max-height;
  }
}

.content {
  // reset content direction to default ltr, independent from the panel's scrollbar position
  direction: ltr;
}

.scroll-panel-wrapper.update-details-container__description-area {
  .content {
    max-height: $scroll-panel-content__height--max-description-area;
  }
}

.scroll-panel-wrapper.split-container--detail-screen {
  width: 100%;
  height: $description-area__height;

  .content {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
}

.scroll-panel-wrapper.split-container--detail-screen.message-field {
  .content {
    position: relative;
    top: 0;
    height: 100%;
  }
}

.scroll-panel-wrapper.partial-popup__content {
  .content {
    padding-right: $spacer__m;
    text-align: left;
  }
}

#{$selector-arabic-layout} {
  direction: rtl; // sets the scroll bar to the left
}

#{$selector-latin-layout} {
  direction: ltr; // sets the scroll bar to the right
}
