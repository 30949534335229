@import "../../../../../../../pagGlobalstyles/sass/global_variables";
@import "../../../../../../../pagGlobalstyles/sass/global_animations";
@import "../../../../../../../pagGlobalstyles/sass/global_mixins";
@import "../../../../../../../pagGlobalstyles/sass/global_functions";
@import "../../../../../../../pagGlobalstyles/sass/pag3_list_styles";

$padding__top: $grid__unit * 2.75;
$padding__bottom: $grid__unit * 2.25;
$list-item__center-area-start-padding: $padding__top 0 $padding__bottom $spacer__xs;
$list-item__center-end-no-padding: 0 $spacer__xs 0 0;

$list-item__center-end-text-padding: $padding__top $spacer__m $padding__bottom 0;
$list-item__center-end-text-padding-arabic: $padding__top 0 $padding__bottom $spacer__m;
$list-item__center-end-text-padding-list-item--nav-search: $padding__top $spacer__l $padding__bottom 0;
$list-item__center-end-text-padding-list-item--nav-search-arabic: $padding__top 0 $padding__bottom $spacer__l;

$list-item-toucharea__padding--list-item-nav-search: 2.75 * $grid__unit $spacer__m 2.25 * $grid__unit 0;
$list-item-toucharea__padding--list-item-nav-search-arabic: 2.75 * $grid__unit 0 2.25 * $grid__unit $spacer__m;

$list-item__center-end__height: $grid__row-height - ($grid__unit / 2);

$list-item-center-area__padding--alt-routes-list-item: 0 $spacer__ml + 1 0;

.list-item-separate-toucharea {
  &__center-content-wrapper {
    display: inline-flex;
    justify-content: space-between;
    flex-direction: row;
    flex-grow: 1;
    position: relative;
    padding: 0;
    overflow: hidden;
  }

  &__center-content-start {
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    padding: $list-item__center-area-padding;
    overflow: hidden;
  }

  &__center-content-end--no-padding {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: $list-item__center-end__height;
    width: $grid__row-height;
    min-width: $grid__row-height;
    font-size: $size__m;
    text-align: center;
    box-sizing: content-box;
  }
}

.list-item-end__touch-divider {
  justify-content: center;

  .list-item__end {
    margin-inline-start: $grid__unit / 2;
  }
}

.base-list-item-separate-toucharea-wrapper.multiple-routes__list-item {
  .list-item-separate-toucharea__center-content-start {
    padding: $list-item-center-area__padding--alt-routes-list-item;
  }
}

.base-list-item-separate-toucharea-wrapper.list-item__nav-search {
  .list-item-separate-toucharea__center-content-start {
    padding: $list-item-toucharea__padding--list-item-nav-search;
  }

  .list-item-separate-toucharea__center-content-end--text-padding {
    padding: $list-item__center-end-text-padding-list-item--nav-search;
  }
}

.base-list-item-separate-toucharea-wrapper.#{$class-name-arabic-layout}.list-item__nav-search {
  // sass-lint:disable-line no-combinators
  .list-item-separate-toucharea__center-content-start {
    padding: $list-item-toucharea__padding--list-item-nav-search-arabic;
  }

  .list-item-separate-toucharea__center-content-end--text-padding {
    padding: $list-item__center-end-text-padding-list-item--nav-search-arabic;
  }
}

.list-item-separate-toucharea__center-content-end--text-padding {
  display: flex;
  padding: $list-item__center-end-text-padding;

  #{$selector-latin-layout} & {
    padding: $list-item__center-end-text-padding;
  }

  #{$selector-arabic-layout} & {
    padding: $list-item__center-end-text-padding-arabic;
  }
}
