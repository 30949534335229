@import "../../../../../../../pagGlobalstyles/sass/global_variables";
@import "../../../../../../../pagGlobalstyles/sass/fonts";

$text-fading__width: $size__l;
$separator__width: $grid__unit / 2;
$separator__height: 9 * $grid__unit;
$separator__color: rgba(255, 255, 255, 0.2);

$margin--end: $grid__unit / 2; // 2px

$address__text--max-width: $grid__column-width * 2 + $spacer__s;

$address-text__padding-left: 7 * $grid__unit; // 28px
$address-text__padding-right: 7.5 * $grid__unit; // 30px

$rrd__padding-right--location-selected: 4.25 * $grid__unit; // 17px
$rrd__margin--location-selected: 0 0 0 (4.5 * $grid__unit); // 0 0 0 18px

.map-tooltip-address-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-inline-end: $margin--end;
  overflow: hidden;
  #{--boxed}: true;

  &.state-focused {
    @include focus-state;
  }
}

.address__text {
  position: relative;
  flex-grow: 1;
  // max-width: $address__text--max-width;
  margin-bottom: $separator__width;
  white-space: nowrap;
  overflow-x: hidden;
  font-size: $font__size--m;
  color: var(--color-highlight, $color__highlight);
}

.rrd {
  @include font-pag-semibold;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  margin-right: $spacer__m;
  margin-left: $spacer__m;
  max-width: 200px;
  color: $font__color--secondary-active;
  font-size: $font__size--normal;

  &__loading-spinner {
    height: $icon-size__m;
    width: $icon-size__m;
  }
}

.map-tooltip-address-item-wrapper.location-selected {
  .rrd {
    font-family: unset;
    padding-right: $rrd__padding-right--location-selected;
  }
}

.map-tooltip-address-item-wrapper.#{$class-name-arabic-layout}.location-selected {
  // sass-lint:disable-line no-combinators
  .address__text {
    padding-right: $address-text__padding-right;
  }

  .rrd {
    margin: $rrd__margin--location-selected;
  }
}

// #{$selector-latin-layout} {
.address__text {
  @include text-fading(left);
  padding-left: $address-text__padding-left;
}
// }

#{$selector-arabic-layout} {
  .address__text {
    @include text-fading(right);
    padding-right: $address-text__padding-right;
  }
}
