@import "../../../../../../../pagGlobalstyles/sass/global_variables";
@import "../../../../../../../pagGlobalstyles/sass/fonts";

$maneuver-guide__width: 64 * $grid__unit; // 256px

$maneuver-stopover-number__top: 9.5 * $grid__unit; // 38px
$maneuver-stopover-number__left: 9.75 * $grid__unit; // 39px
$maneuver-stopover-number__right: 3.25 * $grid__unit; // 13px

$destination-icon__width: $icon-size__l;
$destination-icon__color: var(--color-highlight, $color__highlight);
$destination-icon__color--roundabout-base-icon: $color__white--40;

$tool-tip--second-maneuver__height: 20 * $grid__unit; //80px
$tool-tip--second-maneuver__divider--top: 0.75 * $grid__unit; //-3px
$tool-tip--second-maneuver__divider--height: 0.5 * $grid__unit; //2px
$tool-tip--second-maneuver__divider--width: 185.5 * $grid__unit; //742px
$tool-tip--second-maneuver__padding--small: ($spacer__xs--hor + $spacer__xs); // 14px

$tooltip__padding--left: (4.5 * $grid__unit); // 18px
$tooltip__padding--right: (6.5 * $grid__unit); // 26px

.route-guidance-info-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  width: 100%;
  #{--boxed}: true;
  padding: 0;
  padding-inline-end: $tooltip__padding--right;
  padding-inline-start: $tooltip__padding--left;
}

.maneuver {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: $size__xl;

  &.tool-tip--second-maneuver {
    justify-content: center;
    align-items: flex-end;
    position: relative;
    height: $tool-tip--second-maneuver__height;

    &:before {
      content: "";
      position: absolute;
      top: -$tool-tip--second-maneuver__divider--top;
      height: $tool-tip--second-maneuver__divider--height;
      width: $tool-tip--second-maneuver__divider--width;
      background: $color__white--40;
      opacity: $disabled-opacity;
    }
  }

  &__guide {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    max-width: $maneuver-guide__width;
    overflow: hidden;
    white-space: nowrap;
  }

  &__icon {
    display: flex;
    flex-shrink: 0;
    width: $destination-icon__width;
    height: $destination-icon__width;
    font-size: $destination-icon__width;
    color: $destination-icon__color;

    &::before,
    &::after {
      text-shadow: none;
    }

    &-wrapper {
      display: flex;
      flex-shrink: 0;
      position: relative;
      height: $destination-icon__width;
      min-width: $destination-icon__width;
      margin-inline-end: $spacer__m;
    }

    &--additional {
      display: flex;
      flex-shrink: 0;
      height: auto;
      max-width: $destination-icon__width;
      font-size: $destination-icon__width;
    }

    &--roundabout-base-icon {
      position: absolute;
      width: $destination-icon__width;
      height: $destination-icon__width;
      font-size: $destination-icon__width;

      &::before {
        color: $destination-icon__color--roundabout-base-icon;
      }
    }
  }

  &__stopover-number {
    position: absolute;
    align-self: flex-end;
    font-size: $font__size--s;
    height: $font__size--s;
    width: $font__size--s;
    top: $maneuver-stopover-number__top;
    text-align: right;
  }

  &__direction {
    @include font-pag-numeric-plain;
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
    font-size: $font__size--m;
    color: $color__normal;
  }

  &__cardinal-direction {
    flex-shrink: 0;
    margin-right: $grid__unit;
  }

  &__direction-info {
    flex-grow: 1;
  }

  &__distance-to-maneuver {
    @include font-pag-numeric-plain;
    display: flex;
    flex-shrink: 1;
    flex-grow: 1;
    overflow: hidden;
    font-size: $font__size--m;
    color: var(--color-highlight, $color__highlight);
  }
}

#{$selector-latin-layout} {
  .maneuver {
    &__guide {
      margin-right: $spacer__m;
    }

    &__icon--additional {
      margin-right: $spacer__m;
    }

    &__direction-info {
      @include text-fading(left);
    }

    &__stopover-number {
      left: $maneuver-stopover-number__left;
    }

    &.tool-tip--second-maneuver {
      padding-left: $tool-tip--second-maneuver__padding--small;
    }
  }

  &.tool-tip--small {
    .maneuver__guide {
      margin-right: 0;
    }
  }
}

#{$selector-arabic-layout} {
  .maneuver {
    &__guide {
      direction: rtl;
      margin-left: $spacer__m;
    }

    &__icon--additional {
      margin-left: $spacer__m;
    }

    &__direction-info {
      @include text-fading(right);
    }

    &__stopover-number {
      right: $maneuver-stopover-number__right;
    }

    &.tool-tip--second-maneuver {
      padding-left: $tool-tip--second-maneuver__padding--small;
      direction: ltr;
    }
  }

  &.tool-tip--small {
    .maneuver__guide {
      margin-left: 0;
    }
  }
}
