@import '../../../pagGlobalstyles/sass/global_variables';
@import '../../../pagGlobalstyles/sass/directives_variables'; // sass-lint:disable-line clean-import-paths

$audi-disabled-color: #666; // value from audi High_AU65X_Colourmanagement.pdf

$label-span__line-height: $font__line-height--m;
$label-span__max-height: $label-span__line-height;

.i18nLabel {
    display: block;
}

:host(au3-i18n-label) {
    // not used in react project so far
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre;

    &.state-disabled {
        color: $audi-disabled-color;
    }
}

.i18nLabel {
    overflow: hidden;
    white-space: pre-wrap;

    &.label--with-non-i18n-value {
        white-space: nowrap;
    }
}

.i18nLabel .media-label__inside-label,
.i18nLabel .list-item-text-in-center-first-line {
    .label__span {
        display: block;
        width: 100%;
        max-height: $label-span__max-height;
        line-height: $label-span__line-height;
    }
}

// aaand yet another :host-context
.i18nLabel .pag3-media-i18n-label--centered {
    .label__span {
        display: block;
        width: 100%;
    }
}

#{$selector-arabic-layout} {
    direction: rtl;

    .label__span {
        unicode-bidi: plaintext;
    }
}

// Workaround für Chrome Bug https://github.com/angular/angular/issues/19572
// with "direction:rtl" and "white-space:pre" and font-kerning the rendered text is sometimes 1px wider than normal,
// so it doesn't fit in the element any more. Therefore it would be truncated, even though there might be enough space around the label.
// So deactivate font-kerning in these cases.

// don't use $selector-arabic-layout, because that uses :host-context, which doesn't work together with :host (https://github.com/angular/angular/issues/19199)
// so another workaround: use the CSS-class $class-name-arabic-layout directly, because anything before :host ignores view encapsulation anyway.
.#{$class-name-arabic-layout} :host(au3-i18n-label.label--with-non-i18n-value) {
    // sass-lint:disable-line no-combinators no-qualifying-elements
    font-kerning: none;
}