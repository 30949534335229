.LoginKeyPad {
    height: 100vh;
    width: 100vw;
    overflow: auto;
    position: relative;

    .keypad__pad {
        width: 100%;
    }

    .keypad__wrapper {
        padding-left: 12px !important;
    }

    .key-wrapper {
        width: calc((100% - 150px)/11*4 + 52.5px);
    }

    .key__width-24 {
        width: 100%;
    }

    .LoginKeyPad-Container {
        width: 360px;
        margin: auto;

        .LoginKeyPad-Icon {
            width: 100%;
            text-align: center;
            margin: 0px;
        }

        .LoginKeyPad-Title {
            text-align: center;
            margin-top: 0px;
            font-size: 20px;
            line-height: 30px;
            color: rgb(204, 204, 204);

        }

        .LoginKeyPad-InputField {
            background-color: #1a1a1a;
            height: 100px;
            border: 1px solid #1a1a1a;
            border-radius: 2px;
            box-shadow: inset 0 0 0 2px rgba(204, 204, 204, 0.1);

            .LoginKeyPad-InputField-Text {
                text-align: center;
                font-size: 52px;
                line-height: 60px;
                color: rgb(204, 204, 204);
                transform: translateY(30px);
            }
        }

    }


}


.LoginKeyPad-Error {
    width: 100%;
    background: #950014;
    color: #fff;
    text-align: center;
    cursor: pointer;
    padding: 4px;
    font-size: 16px;
    line-height: 21px;
    height: 30px;
}