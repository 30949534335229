@import "../../../pagGlobalstyles/sass/global_variables";
@import "../../../pagGlobalstyles/sass/global_animations";
@import "../../../pagGlobalstyles/sass/fonts";

$tile__width: 40 * $grid__unit - 3;
$tile__icon-width: 157px;
$tile__icon-small-width: 78px;
$tile__icon-size--small: 55px;

$tile__text--font-size: $font__size--normal;
$tile__text--line-height: $spacer__xl;
$tile__text--max-height: $size__xl;
$tile__text-fade: linear-gradient(to right, transparent 0, $color__primary--first 56px);
$tile__text-fade--arabic: linear-gradient(to left, transparent 0, $color__primary--first 56px);

.tile {
  @include font-pag-plain;
  #{--boxed}: true;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 235px;
  will-change: transform;

  > * {
    pointer-events: none; // @NOTE(kirill): Clicking on nested element doesn’t trigger the click we need.
  }

  &.state-focused {
    .tile__icon-wrapper {
      @include focus-state("tile");
    }
  }

  &__icon-wrapper {
    width: $tile__icon-width;
    height: $tile__icon-width;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    border-radius: 23px;
    border: 3px solid transparent;
    padding: 2px;
    background-image:
      linear-gradient(152deg, #141414 0%, #292929 83%, #333333 100%, rgba(255, 255, 255, 0.28) 100%),
      linear-gradient(#000, #000)
    ;
    background-clip: content-box, padding-box;
    font-size: $size__xl;

    &::before {
      content: "";
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -3px;
      border-radius: inherit;
      background-image: linear-gradient(45deg, #363636, #2E2E2E);
    }
  }

  &__icon {
    display: block;
    position: relative;
    line-height: $size__xl;
    user-select: none;
  }

  &--small {
    .tile__icon-wrapper {
      width: $tile__icon-small-width;
      height: $tile__icon-small-width;
      border-width: 2px;
      padding: 1px;
      border-radius: 15px;
    }

    &.drag-over-replace {
      .tile__icon-wrapper {
        &::before {
          background-image: linear-gradient(152deg, var(--color-highlight, $color__highlight), #0F448C);
        }
      }
    }

    .icon {
      font-size: $tile__icon-size--small;
    }
  }

  &__text {
    max-width: $tile__width;
    margin-top: $spacer__s;
    font-size: $tile__text--font-size;
    line-height: $tile__text--line-height;
    text-align: center;
    color: $color__white--80;
    min-width: 235px;
  }

  &__text--overflow {
    display: flex;
    max-height: $tile__text--max-height;
    overflow: hidden;
    position: relative;
  }

  &__count {
    position: absolute;
    font-family: $font--plain-numeric;
    font-size: $tile__text--font-size;
  }

  &.state-pressed,
  &:active {
    .tile__icon-wrapper {
      &::after {
        position: absolute;
        content: "";
        display: block;
        background-color: rgba(204, 204, 204, 0.1);
        top: 0; right: 0; bottom: 0; left: 0;
        border-radius: inherit;
        margin: -3px;
      }
    }

    .tile__icon {
      transform: scale(0.9);
    }

    & .animated-bg--growing-circle {
      // sass-lint:disable-line no-combinators
      @include animation(
        pressed-growing-circle $time__animation-duration--slow $animation-timing-function--bezier-easeout 1 both
      );
    }

    & .animated-bg--pressed {
      // sass-lint:disable-line no-combinators
      @include animation(
        pressed-background $time__animation-duration--slow $animation-timing-function--bezier-easeout 1 both
      );
      @include border("pressed");
    }
  }

  &--small.state-pressed,
  &--small:active {
    .tile__icon-wrapper::after {
      border: 1px solid #979797;
      margin: -2px;
    }
  }

  &.state-active {
    .tile__icon-wrapper {
      @include background("buttons-tiles", "active");
      border: 0;
    }

    .tile__text {
      color: var(--color-highlight, $color__highlight);
    }

    .tile__icon {
      @include icon-style("active", "bevel");
    }

    .tile__svg {
      @include icon-style("active", "bevel", 1, true);
    }
  }

  &.state-disabled {
    cursor: not-allowed;

    .tile__icon-wrapper {
      @include background("buttons-tiles", "disabled");
    }

    .tile__icon {
      @include icon-style("disabled", "bevel");
    }

    .tile__svg {
      @include icon-style("disabled", "bevel", 1, true);
    }

    .tile__text {
      color: $color__disabled;
    }
  }
}

#{$selector-arabic-layout} {
  .tile__text--overflow {
    &::before {
      bottom: 0;
      left: 0;
      background-image: $tile__text-fade--arabic;
    }
  }
}
