@import "../../../../../../../pagGlobalstyles/sass/global_variables";
@import "../../../../../../../pagGlobalstyles/sass/fonts";

$poi-icon__margin-top: 2.5 * $grid__unit;
$poi-icon__margin-right: $spacer__s + $spacer__xs--hor;

$poi-stack-icon__width: 11.5 * $grid__unit; // 46px
$poi-stack-icon__font-size: 11.75 * $grid__unit; // 47px

$poi-stack__icons-container--max-width: $grid__column-width * 2 + $spacer__s;

.poi-stack-wrapper {
  display: block;
  #{--boxed}: false;
}

.poi-stack {
  display: flex;
  align-items: center;
  width: 100%;

  &__icons-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: hidden;
    max-width: $poi-stack__icons-container--max-width;
  }

  &__icon {
    width: $poi-stack-icon__width;
    height: $size__m;
    font-size: $poi-stack-icon__font-size;
    margin-top: $poi-icon__margin-top;
    flex: 0 0 auto;
    cursor: pointer;

    &::before {
      text-shadow: none;
    }
  }

  &__amount {
    display: flex;
    align-self: center;
    font-size: $font__size--normal;
    color: $color__normal;
    height: $poi-stack-icon__font-size;

    &::before {
      text-shadow: none;
    }
  }
}

// #{$selector-latin-layout} {
.poi-stack__icon {
  pointer-events: all; //custom
  margin-right: $poi-icon__margin-right;
}
// }

#{$selector-arabic-layout} {
  .poi-stack__icon {
    margin-left: $poi-icon__margin-right;
  }
}
