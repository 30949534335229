@import "./global_variables";
$animation__size--start: 8 * $grid__unit;

@include keyframes(pressed-growing-circle) {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.1);
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(1);
  }
}

@include keyframes(pressed-background) {
  0%,
  17% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
