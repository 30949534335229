@import "../../../../../../../pagGlobalstyles/sass/global_variables";

.distance-destination-wrapper {
  overflow: hidden;
  #{--boxed}: true;
}

.distance__value {
  display: flex;
  white-space: nowrap;
}
