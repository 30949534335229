@import "../../../../../../../pagGlobalstyles/sass/global_variables";

$address__headline--line-height: $font__size--m + $spacer__l;
$address__headline--margin: $spacer__s;
$address__headline--margin-top: 3.5 * $grid__unit; // 15px

$address__favorite-icon-after--height: 7.5 * $grid__unit; // 30px
$address__favorite-icon-after--top: 112px / 2 - ($address__favorite-icon-after--height / 2); // 41px
$address__favorite-icon-after--left: 6.5 * $grid__unit;

$address__favorite-icon--top: 19.5 * $grid__unit; // 78px
$address__favorite-icon--right: -$spacer__m; // -16px
$address__favorite-icon--size: 28 * $grid__unit; // 112px

$address__width: 179 * $grid__unit;
$address__headline--max-width: $address__width - $icon-size__m - $spacer__s - $spacer__m;
$address__second-line--max-width: $address__headline--max-width - $address__favorite-icon-after--left - $spacer__m;

$back-icon__size: $size__m;

$provider-icon__height: 25 * $grid__unit; // 100px
$provider-icon__width: 100 * $grid__unit; // 400px
$provider-icon__fontsize: 37.5 * $grid__unit; // 150px

$back-button__margin-top--poi-stack: 4.5 * $grid__unit; // 18px

.map-tooltip-address-item-details-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  #{--boxed}: true;
}

.address__headline {
  font-size: $font__size--m;
  height: $address__headline--line-height;
  width: $address__headline--max-width;
  margin-top: $address__headline--margin-top;
  margin-bottom: $address__headline--margin;
  color: var(--color-highlight, $color__highlight);
  overflow: hidden;
  white-space: nowrap;

  &.poi-stack {
    padding-left: $spacer__xl;
  }
}

.address__second-line,
.address__third-line {
  font-size: $font__size--normal;
  max-width: $address__second-line--max-width;
  line-height: $spacer__xl;
  overflow: hidden;
  white-space: nowrap;
}

.address__favorite-icon {
  position: absolute;
  top: $address__favorite-icon--top;
  font-size: $icon-size__m;
  width: $address__favorite-icon--size;
  height: $address__favorite-icon--size;

  &:after {
    content: "";
    position: absolute;
    top: $address__favorite-icon-after--top;
    height: $address__favorite-icon-after--height;
    width: $size__divider-width;
    background-color: $color__border--enabled;
  }

  .state-focused {
    @include focus-state(default, relative, after);
  }
}

.headline-wrapper {
  display: flex;
  flex-direction: row;
}

.back-button {
  width: $back-icon__size;
  height: $back-icon__size;
  font-size: $back-icon__size;
  color: $color__white--60;

  &::before {
    text-shadow: none;
  }

  &.state-focused {
    @include focus-state(default, none, after);
  }
}

.map-tooltip-address-item-details-wrapper.poi-stack--expanded {
  .back-button {
    margin-top: $back-button__margin-top--poi-stack;
    margin-left: $spacer__m;
  }
}

// #{$selector-latin-layout} {
.address__headline,
.address__second-line,
.address__third-line {
  @include text-fading(left);
}

.address__favorite-icon {
  right: $address__favorite-icon--right;

  &:after {
    left: 0;
  }
}
// }

.powered-by-google {
  font-size: $provider-icon__fontsize;
  display: flex;
  height: $provider-icon__height;
  width: $provider-icon__width;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: visible;
}

#{$selector-arabic-layout} {
  .address__headline,
  .address__second-line,
  .address__third-line {
    @include text-fading(right);
    direction: rtl;
    margin-right: $spacer__s;
  }

  .address__favorite-icon {
    left: $address__favorite-icon--right;

    &:after {
      right: 0;
    }
  }
}
