@font-face {
  font-family: "Porsche Next Auto TT";
  font-style: italic;
  font-weight: normal;
  src: url("./fonts/fonts/PNext_Font_Latin_AR/PorscheNextAutoTT-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "Porsche Next Auto TT";
  font-style: normal;
  font-weight: 100;
  src: url("./fonts/fonts/PNext_Font_Latin_AR/PorscheNextAutoTT-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Porsche Next Auto TT";
  font-style: normal;
  font-weight: 300;
  src: url("./fonts/fonts/PNext_Font_Latin_AR/PorscheNextAutoTT-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Porsche Next Auto TT";
  font-style: bold;
  font-weight: 700;
  src: url("./fonts/fonts/PNext_Font_Latin_AR/PorscheNextAutoTT-SmBold.ttf") format("truetype");
}

@font-face {
  font-family: "Porsche Next Auto TT";
  font-style: bold;
  font-weight: 800;
  src: url("./fonts/fonts/PNext_Numeric_Exp/PorscheNextAutoExpTT-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Porsche Next Auto TT";
  font-style: bold;
  font-weight: 900;
  src: url("./fonts/fonts/PNext_Numeric_Exp/PorscheNextAutoExpTT-Bold.ttf") format("truetype");
}

html {
  --key: #00b0f4;
  --select: #e2b236;
  --strong: #b33229;
  --green: #13d246;
  background-color: black;
  font-size: 16px;
}

body {
  overflow: hidden;
}

body * {
  font-family: "Porsche Next Auto TT", "Porsche-UI-Icons";
  user-select: none;
  box-sizing: border-box;
}

.animated-bg--pressed {
  border-radius: 2px;
}

.animated-bg--pressed {
  background-image: -webkit-gradient(linear, left bottom, left top, color-stop(0, #001e38), to(#06131b));
  /*background-image: linear-gradient(0deg, #001e38 0, #06131b);*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.debugtext {
  color: slategray;
  font-style: "Porsche next Auto TT";
}
