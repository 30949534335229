@import "../../../pagGlobalstyles/sass/global_variables";

$list-item-end-text__margin: 8 * $grid__unit;

$list-item-center-text-first__line-height: $font__line-height--m; // 54px
$list-item-center-text-first__line-height--allow-line-break: 2 * $list-item-center-text-first__line-height;
$list-item-center-text-second__line-height: 10.5 * $grid__unit; // 42px
$list-item-center-text-second__line-height--media: $spacer__xl; // 48px
$list-item-center-text-second__line-height--route-list: 10.75 * $grid__unit; // 43px
$list-item-center-text-second__line-height--asia: 23 * $grid__unit; // 92px

$list-item-end-text-first__line-height: 2 * $font__line-height--m; // 54px one line of text. Max we can have 2 lines
$list-item-end-text-second__line-height: (10.5 * $grid__unit) * 7; // 42px one line of text. Max we can have 7 lines.
$list-item-end__max-width: 98 * $grid__unit; // 392px
$list-item-end__max-width--avoiding: 140 * $grid__unit; // 560px
$list-item-end__max-width--half-and-half: 55 * $grid__unit; // 220px
$list-item-end__max-height--half-and-half: 2 * $list-item-center-text-second__line-height; // 84px

$big-text-component-first-line__margin--top: 2.75 * $grid__unit;
$big-text-component-first-line__color: $color__white--80;
$big-text-component-first-line__min-width--notification-center-slideout: 85.5 * $grid__unit; // 342px
$big-text-component-first-line__min-width--myscreen-edit: 89.5 * $grid__unit; // 358px

$big-text-component-second-line__margin--top: -0.25 * $grid__unit; // -1px
$big-text-component-second-line__margin--bottom: -0.75 * $grid__unit; // -3px
$big-text-component-second-line__color: $color__white--60;
$big-text-component-second-line__padding-bottom--multiline: 5.25 * $grid__unit; //30px - 9px = 21px

$list-item-text-in-center-first-line__line-height: $font__line-height--m;
$list-item-text-in-center-first-line__max-height: $list-item-text-in-center-first-line__line-height;

$speech-help-concept__min-width: 227 * $grid__unit; //908px
$speech-number-disambiguation__min-width: 231 * $grid__unit; //924px
$speech-source-selection__min-width: 212 * $grid__unit; //848px

.big-text {
  display: flex;
  flex-direction: column;

  &.list-item__center-end--rrd-dopi {
    .big-text-component__first-line {
      margin-top: $big-text-component-first-line__margin--top;
      font-size: $font__size--normal;
    }
  }
}

.big-text-component {
  &__first-line {
    @include font-size(l);
    display: flex;
    color: $big-text-component-first-line__color;

    & .label--with-non-i18n-value {
      white-space: nowrap;
    }
  }

  &__second-line {
    @include font-size(s);
    display: flex;
    margin-top: $big-text-component-second-line__margin--top;
    margin-bottom: $big-text-component-second-line__margin--bottom;
    color: $big-text-component-second-line__color;
    overflow: hidden;

    & .label--with-non-i18n-value {
      white-space: nowrap;
    }
  }

  &__hidden {
    display: none;
  }
}

.big-text.state-collapsed {
  .big-text-component__first-line,
  .big-text-component__second-line {
    color: $color__disabled;
  }
}

.big-text.state-disabled {
  .big-text-component__first-line,
  .big-text-component__second-line {
    color: $color__disabled;
  }
}

.state-pressed {
  .big-text-component__first-line,
  .big-text-component__second-line {
    color: $color__white--100;
  }
}

.state-active {
  .big-text-component__first-line {
    color: var(--color-highlight, $color__highlight);
  }

  .big-text-component__second-line {
    color: $color__white--80;
  }
}

.state-active.state-focused {
  .big-text-component__first-line,
  .big-text-component__second-line {
    color: $color__white--100;
  }
}

.list-item--singleline {
  .big-text-component__first-line,
  .big-text-component__second-line {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .big-text-component__first-line {
    max-height: $list-item-center-text-first__line-height;
  }

  .big-text-component__second-line {
    max-height: $list-item-center-text-second__line-height;
  }

  .list-item-text-in-center-first-line {
    max-height: $list-item-text-in-center-first-line__max-height;
    line-height: $list-item-text-in-center-first-line__line-height;
    overflow: hidden;
  }
}

.big-text.tourplan-list__item {
  .big-text-component__first-line {
    overflow: unset;
  }
}

.list-item--multiline {
  .list-center-content {
    // .big-text.#{$class-name-latin-layout} & {
    .big-text-component__first-line {
      // sass-lint:disable-line nesting-depth
      max-height: $list-item-end-text-first__line-height;
    }

    .big-text-component__second-line {
      // sass-lint:disable-line nesting-depth
      max-height: $list-item-end-text-second__line-height + $spacer__l;
    }
    // }

    // .big-text.#{$class-name-arabic-layout} & {
    //   .big-text-component__first-line {
    //     // sass-lint:disable-line nesting-depth
    //     max-height: $list-item-end-text-first__line-height;
    //   }

    //   .big-text-component__second-line {
    //     // sass-lint:disable-line nesting-depth
    //     max-height: $list-item-end-text-second__line-height + $spacer__l;
    //   }
    // }
  }
}

.big-text.list-item--multiline__asia {
  .big-text-component__first-line {
    max-height: $list-item-center-text-first__line-height;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .big-text-component__second-line {
    max-height: $list-item-center-text-second__line-height--asia;
    white-space: pre-wrap;
  }
}

.big-text.list-item-start {
  .big-text-component__first-line,
  .big-text-component__second-line {
    @include font-size(s);
    max-height: $list-item-center-text-second__line-height;
  }
}

.big-text.drive-main {
  .big-text-component__first-line,
  .big-text-component__second-line {
    margin-inline-end: $list-item-end-text__margin;
    max-width: $list-item-end__max-width;
  }

  .list-item-end__first-line--allow-line-break {
    max-width: $list-item-end__max-width;
  }
}

.slideout--one-and-a-half-tab {
  .big-text-component__first-line {
    margin-inline-end: 0;
  }
}

.list-item-end {
  .big-text-component__first-line,
  .big-text-component__second-line {
    @include font-size(s);
    max-width: $list-item-end__max-width;
    margin-inline-end: $list-item-end-text__margin;
    white-space: nowrap;
  }

  .list-item-end__first-line--standard {
    max-height: $list-item-center-text-second__line-height;
  }

  &.big-text-component--avoiding {
    .list-item-end__first-line--standard {
      max-height: $icon-size__m;
      max-width: $list-item-end__max-width--avoiding;
      margin-inline-end: unset;
    }
  }

  .list-item-end__first-line--allow-line-break {
    max-height: $list-item-center-text-first__line-height--allow-line-break;
  }
}

.big-text.steering-wheel-joker-key__list-item .list-item-end {
  // sass-lint:disable-line no-combinators
  .big-text-component__first-line,
  .big-text-component__second-line {
    max-width: 110 * $grid__unit; // 440px
  }
}

.big-text .source-browser-list-item--item-end {
  .list-item-end__first-line--standard {
    max-height: $list-item-center-text-second__line-height--media;
  }
}

.big-text.timer-config {
  .big-text-component__first-line {
    max-width: unset;
  }
}

.list-item--multiline {
  .big-text-component__second-line {
    padding-bottom: $big-text-component-second-line__padding-bottom--multiline;
  }
}

.big-text.route__start-button {
  .big-text-component__first-line {
    display: flex;
    justify-content: center;
  }
}

.big-text.notification-center__slideout {
  .big-text-component__first-line {
    min-width: $big-text-component-first-line__min-width--notification-center-slideout;
  }
}

.big-text.second-line-wrapper {
  .big-text-component__second-line {
    max-height: $font__line-height--s;
  }
}

.big-text.battery-overview,
.big-text.profile-overview {
  .big-text-component__first-line {
    max-width: 100%;
  }
}

.big-text.maintenance__list-item {
  .big-text-component__second-line {
    color: $color__white--80;
  }
}

.list-item-end {
  .big-text.half-and-half-container--interaction-screen & {
    .big-text-component__first-line,
    .big-text-component__second-line {
      max-width: $list-item-end__max-width--half-and-half;
      max-height: $list-item-end__max-height--half-and-half;
      text-align: end;
    }
  }
}

.big-text.readout-active {
  .big-text-component__first-line,
  .big-text-component__second-line {
    color: $color__active;
  }
}

// sass-lint:disable no-combinators

.list__item--checkbox .big-text.state-active,
.list__item--radio .big-text.state-active,
.list-item-end .big-text.state-active {
  .big-text-component__first-line {
    color: $color__white--80;
  }
}

.big-text.multiple-routes__list-item {
  .big-text-component__second-line {
    padding-bottom: 0;
  }
}

// sass-lint:enable no-combinators

.big-text.darkener {
  .big-text-component__first-line {
    color: $color__white--40;
  }

  .big-text-component__second-line {
    color: $color__white--30;
  }
}

.big-text.battery-overview--last-item {
  .big-text-component__first-line {
    justify-content: flex-end;
  }
}

.big-text.my-screen__categories-list-container {
  .big-text-component__first-line {
    min-width: $big-text-component-first-line__min-width--myscreen-edit;
  }
}

.big-text.list-item__center-end--powered-by-google {
  .list-item-end__first-line--standard {
    flex-grow: 1;
  }
}

.big-text.speech-help-concept {
  .big-text-component__first-line {
    min-width: $speech-help-concept__min-width;
  }
}

.big-text.speech-number-disambiguation {
  .big-text-component__first-line {
    min-width: $speech-number-disambiguation__min-width;
  }
}

.big-text.speech-source-selection {
  .big-text-component__first-line {
    min-width: $speech-source-selection__min-width;
  }
}
